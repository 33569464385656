
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Component, Prop } from 'vue-property-decorator';
import { isPast } from 'date-fns';
import {
  MsoPostRegionalSummary,
  PostListItem,
} from '@/models/posts/postListItem';
import { EventTypes, EventTypesLabels } from '@/constants';
import {
  getActualDate,
  getDisplayedDate,
  getPostChannels,
} from '@/services/eventFactory';
import getAssetThumbnail from '@/helpers/get-asset-thumbnail';

@Component({
  methods: { getActualDate, getPostChannels },
})
export default class PostPreview extends LoggedInComponentBase {
  @Prop() post!: PostListItem;

  get imageUrl() {
    return getAssetThumbnail(this.post.asset!);
  }

  public get displayedPostType(): string {
    if (this.post.isExpired) {
      return 'expired';
    }
    // @ts-ignore
    return EventTypes.find((type) => type.name === this.post.postType)
      .labelSingular;
  }

  public get displayedPostTime(): string {
    const formatter = 'h:mmaaa';
    return getDisplayedDate(getActualDate(this.post), { formatter });
  }

  public get isStorePost(): boolean {
    return this.post.postType === EventTypesLabels.StorePosts;
  }

  public get storePostLabel(): string {
    let label;
    if (this.post.isMsoPost) {
      label = this.post.msoPostProgess!;
    } else {
      const postTime = new Date(getActualDate(this.post));
      label = isPast(postTime) ? 'Posted' : 'Scheduled';
    }
    return label;
  }

  public get postTypeClasses() {
    if (!this.post.isExpired) {
      return {
        'type-store': this.post.postType === EventTypesLabels.StorePosts,
        'type-recommended':
          this.post.postType === EventTypesLabels.RecommendedPosts,
      };
    }
    return null;
  }

  public get showMsoPostSummary(): boolean {
    return (
      this.isRenderedAsMsoPost ||
      this.post.postType === EventTypesLabels.AutomatedPosts ||
      this.post.postType === EventTypesLabels.RecommendedPosts
    );
  }

  public msoPostSummaryInRegion(
    postRegionalSummary: MsoPostRegionalSummary
  ): string {
    const { state, count: numStoresPostedTo } = postRegionalSummary;
    const numStoresInRegion = this.storesModule.regionalStoreCount.get(state);
    return this.post.postType === EventTypesLabels.RecommendedPosts
      ? state
      : `${state}: ${numStoresPostedTo}/${numStoresInRegion}`;
  }

  /**
   * A post created by an MSO is only displayed as a mso post (i.e. renders additional information)
   * when not in single store view (MSO viewing a single store or SSO).
   */
  private get isRenderedAsMsoPost(): boolean {
    return !!this.post.isMsoPost && !this.userModule.isViewingSingleStore;
  }
}
